// 移动端首页
<template>
  <div class="m_total" id="mtotal">
    <!-- 置顶top -->
    <Mtop ref="mtop"></Mtop>
    <div class="m_record" v-show="$store.state.mhome">
      <transition name="mcenter_13">
        <div class="m_record_tag" v-show="enter">
          <a
            class="m_record_tag-list"
            :class="{ 'm_record_tag-list-act': topact == 0 }"
            @click="cliTag(0)"
          >
            收藏夹
          </a>
          <a
            class="m_record_tag-list"
            :class="{ 'm_record_tag-list-act': topact == 1 }"
            @click="cliTag(1)"
          >
            历史记录
          </a>
        </div>
      </transition>
      <div class="m_record_tag_block"></div>
      <transition name="mcenter_13">
        <div class="mhis_box" v-show="(isrec && playList) || (!isrec && playList2)">
          <div class="mhis_0">
            <span v-show="editShow" class="medit" @click="delrecEdit()">
              删除选中( {{ editList.length }} )
            </span>
            <div v-show="!editShow" class="m_edit_p">
              <span @click="recEdit(false)">批量编辑</span>
              <div class="top_piliang"></div>
            </div>
            <span v-show="editShow" class="medit_cancel" @click="recEdit(true)">取消编辑</span>
          </div>
          <div class="mhis_0" @click="clearhis" v-show="!isrec && playList2 && !editShow">
            <span>清空</span>
            <div class="top_del"></div>
          </div>
        </div>
      </transition>
      <!-- 收藏夹内容 -->
      <!-- <transition name="mcenter_4">
        <div v-show="enter"> -->
      <div class="msearch_res" v-show="isrec">
        <!-- 遍历 -->
        <div
          class="msearch_res-box"
          v-for="(item, index) in playList"
          :key="index"
          @click="goto(item, index)"
          :class="{
            'mfav_edit_list-check': editList.filter((itemindex) => itemindex === index).length,
            'mfav_edit_list-uncheck': !editList.filter((itemindex) => itemindex === index).length,
          }"
        >
          <div class="msearch_res-left">
            <!-- <div
              class="msearch_res-img"
              :style="{ backgroundImage: 'url(' + item.video_pic + ')' }"
            > -->
            <img
              class="msearch_res-img"
              :src="item.video_pic ? item.video_pic : ''"
              :onerror="$store.state.defaultimg"
              width="108"
              alt="收藏夹视频封面"
            />
            <span v-show="!item.multiple_fragment && item.video_duration">
              {{ YS.tomin(item.video_duration) }}
            </span>
            <span v-show="item.multiple_fragment && item.video_state == '更新中'">
              更新至{{ item.last_fragment_symbol }}集
            </span>
            <span v-show="item.multiple_fragment && item.video_state == '已完结'">
              {{ item.last_fragment_symbol }}集全
            </span>
            <div
              v-show="editShow"
              class="mfav"
              :class="{
                'mfav-check': editList.filter((itemindex) => itemindex === index).length,
                'mfav-uncheck': !editList.filter((itemindex) => itemindex === index).length,
              }"
            ></div>
            <!-- </div> -->
          </div>
          <div class="msearch_res-right">
            <div class="msearch_abox">
              <a class="msearch_res-title">{{ item.video_title }}</a>
              <!-- <a class="msearch_res-nodes">简介：{{ item.video_description }}</a> -->
              <a class="msearch_res2">导演：{{ item.video_director }}</a>
              <a class="msearch_res3">主演：{{ item.video_starring }}</a>
            </div>

            <div class="msearch_bbox">
              <div class="msearch_res-biaoqian">
                <a v-show="$store.state.menudist[item.video_category_id]">
                  {{ $store.state.menudist[item.video_category_id] }}
                </a>
                <a v-show="item.video_region">{{ item.video_region }}</a>
                <a v-show="item.video_language">{{ item.video_language }}</a>
              </div>
              <div class="msearch_res-time">
                <img src="../../assets/img/m_his.png" alt="收藏时间图标" />
                <a>{{ YS.toTime2(item.create_time) }}</a>
              </div>
            </div>
          </div>
          <div
            class="msearch_res-edit"
            v-show="editList.filter((itemindex) => itemindex === index).length"
          ></div>
        </div>
        <transition name="show_fivPush">
          <div v-show="loadApiNum > 0 && !hisMAX" class="fivPush"><div class="dots-6"></div></div>
        </transition>
        <div class="msearch_res-box-bottom" v-show="playList"></div>
        <!-- 暂无记录 -->
        <div class="record_none" v-show="!playList && islogin && firstLoading">
          <img src="../../assets/img/mnone@2x.png" alt="没有收藏内容显示的图片" />
          <span>暂无记录</span>
        </div>
        <!-- 暂无记录 -->
        <div class="record_none" v-show="!playList && !islogin && firstLoading">
          <img src="../../assets/img/mnone@2x.png" alt="没有收藏内容显示的图片" />
          <span>请先登录</span>
        </div>
      </div>
      <!-- </div>
      </transition> -->
      <!-- 历史记录内容 -->
      <div class="mhis" v-show="!isrec">
        <div
          class="mhis_edit_box"
          :class="{
            'mhis_edit_list-check': editList.filter((itemindex) => itemindex === index).length,
            'mhis_edit_list-uncheck': !editList.filter((itemindex) => itemindex === index).length,
          }"
          v-for="(item, index) in playList2"
          :key="index"
        >
          <div class="mhis_list" :class="{ mhis_edit: editShow }" @click="seekgoto(item, index)">
            <div class="mhis_top">
              <span class="title_a">
                {{ item.video_title }}
                <span class="title_a" v-show="item.video_fragment_symbol && item.multiple_fragment">
                  第{{ item.video_fragment_symbol }}集
                </span>
              </span>
              <span>{{ YS.toTimeNow(item.create_time) }}</span>
            </div>
            <div class="mhis_bottom">
              <!-- v-show="(item.elapsed || item.elapsed == 0) && item.video_duration" -->
              <div class="mhis_chang">
                <div
                  class="mhis_chang_2"
                  :style="'width:' + Math.floor((item.elapsed * 100) / item.video_duration) + '%'"
                >
                  <div class="mhis_chang_3"></div>
                </div>
              </div>
              <!-- 这里的进度条对应的是class mhis_chang_2 -->
              <a v-show="item.elapsed && item.video_duration">
                {{ Math.floor((item.elapsed * 100) / item.video_duration) }}%
              </a>
            </div>
          </div>
          <transition name="medit_cover_show">
            <div
              class="medit_cover"
              @click="mcheckRecEdit(item, index)"
              v-show="editList.filter((itemindex) => itemindex === index).length"
            ></div>
          </transition>
          <transition name="medit_show">
            <div class="medit_check" @click="mcheckRecEdit(item, index)" v-show="editShow"></div>
          </transition>
        </div>
        <transition name="show_fivPush">
          <div v-show="loadApiNum > 0 && !favMAX" class="fivPush"><div class="dots-6"></div></div>
        </transition>
        <div class="record_bottom_0" v-show="!topact"></div>
        <!-- 暂无记录 -->
        <div class="record_none" v-show="!playList2 && firstLoading">
          <img src="../../assets/img/mnone@2x.png" alt="没有历史记录显示的图片" />
          <span>暂无记录</span>
        </div>
      </div>
    </div>
    <!-- <div class="mhis_block" v-show="topact"></div> -->
    <Mbottom></Mbottom>
    <!-- WIN 确认清空 -->
    <transition name="mcenter_8">
      <div class="mpayresult" v-show="clearR">
        <div class="confirn_mess1">
          <img class="outimg" src="../../assets/img/leave_vip.png" alt="确认清空弹窗提示图片" />
          <span class="outspan">是否确认清空历史记录？</span>
        </div>
        <div class="confirn_buttons">
          <a class="outa1" @click="clearhisYes">确认</a>
          <a class="outa2" @click="openandclo('clearR', false)">取消</a>
        </div>
        <img
          class="login-close"
          @click="openandclo('clearR', false)"
          src="../../assets/img/close.png"
          alt="关闭退出登录确定弹窗"
        />
      </div>
    </transition>
    <!-- WIN 确认清除N条历史记录 -->
    <transition name="mcenter_8">
      <div class="mpayresult" v-show="clearEdit">
        <div class="confirn_mess1">
          <img class="outimg" src="../../assets/img/leave_vip.png" alt="确认清空弹窗提示图片" />
          <span class="outspan">是否确认删除{{ editList.length }}条历史记录？</span>
        </div>
        <div class="confirn_buttons">
          <a class="outa1" @click="clearhisEdit">确认</a>
          <a class="outa2" @click="openandclo('clearEdit', false)">取消</a>
        </div>
        <img
          class="login-close"
          @click="openandclo('clearEdit', false)"
          src="../../assets/img/close.png"
          alt="关闭退出登录确定弹窗"
        />
      </div>
    </transition>
    <!-- WIN 确认清除N条收藏夹内容 -->
    <transition name="mcenter_8">
      <div class="mpayresult" v-show="clearFavEdit">
        <div class="confirn_mess1">
          <img class="outimg" src="../../assets/img/leave_vip.png" alt="确认清空弹窗提示图片" />
          <span class="outspan">是否确认删除{{ editList.length }}条收藏内容？</span>
        </div>
        <div class="confirn_buttons">
          <a class="outa1" @click="clearfavEdit">确认</a>
          <a class="outa2" @click="openandclo('clearFavEdit', false)">取消</a>
        </div>
        <img
          class="login-close"
          @click="openandclo('clearFavEdit', false)"
          src="../../assets/img/close.png"
          alt="关闭退出登录确定弹窗"
        />
      </div>
    </transition>
    <!-- 遮罩层 -->
    <div id="coverm"></div>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Mtop from '../../components/moblie/mTop';
import Mbottom from '../../components/moblie/mBottom';
export default {
  components: {
    Mtop,
    Mbottom,
  },
  name: 'mRecode',
  computed: {
    isvip: () => {
      return that.$store.state.isvip;
    },

    islogin: () => {
      return that.$store.state.islogin;
    },
  },
  data() {
    return {
      loadApiNum: 0,
      pages_his: {
        page: 1,
        pagesize: 15,
      },
      pages_fav: {
        page: 1,
        pagesize: 15,
      },
      hisMAX: false, //历史记录加载上限
      favMAX: false, //收藏夹加载上限
      editList: [],
      editIDList: [],
      editShow: false,
      his_show: true,
      clearR: false,
      clearEdit: false,
      clearFavEdit: false,
      topact: 0, //顶部选择
      enter: false, //动画过渡中间人
      isrec: true,
      pagetotal: null,
      playList: null,
      pagetotal2: null,
      playList2: null,
      firstLoading: false,
      user: {}, //用户信息
      tokenval: {}, //token信息
      timewindowScroll_mrecord: () => {},
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    that;
    fetch;
    vuex;
    that.$store.commit('changelogin');
    this.timewindowScroll_mrecord = this.YS.fnThrottle(this.windowScroll, 50);
  },
  destroyed() {
    window.removeEventListener('scroll', this.timewindowScroll_mrecord);
  },
  mounted() {
    window.addEventListener('scroll', this.timewindowScroll_mrecord);
    that.$store.commit('changelogin');
    that.enter = true;
    that.user = JSON.parse(localStorage.getItem('user'));
    that.tokenval = JSON.parse(localStorage.getItem('token'));
    //判断当前是否登录
    if (that.$store.state.islogin) {
      //已经登录状态
      // that.getFavlist(); // api获取收藏夹
      that.getPlaylist(); //获取历史记录
      // url传参进入历史记录,true为收藏夹
      if (that.$route.query.isrec === 'true' || that.$route.query.isrec === true) {
        that.isrec = true;
        that.getFavlist(); // api获取收藏夹
        // this.YS.actgo('m_record_tag-list', 'm_record_tag-list-act', 1);
        that.topact = 0;
        //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
        if (!that.YS.ismob()) {
          //web端地址
          window.location.href = window.g.WEB_URL + '/history.html';
        }
      } else {
        that.topact = 1;
        that.isrec = false;
      }
    } else {
      // 未登录状态
      // alert('需要登录才能进行操作，即将前往登录');
      // //添加vuex队列方法，用于登录后
      // that.$store.commit('addloginBefore', () => {
      //   that.YS.routetogo({ path: '/mrecord' });
      // });
      // that.YS.routetogo({ path: '/mlogin' });
      that.topact = 1;
      that.isrec = false;
      if (localStorage.getItem('not_logged_history')) {
        that.playList2 = JSON.parse(localStorage.getItem('not_logged_history'));
        // that.playList2 = null;
      }
    }
  },

  methods: {
    //点击删除选中
    delrecEdit() {
      if (that.isrec) {
        //收藏夹
        that.openandclo('clearFavEdit', true);
      } else {
        //历史记录
        that.openandclo('clearEdit', true);
      }
    },
    //确认删除选中,历史记录
    async clearhisEdit() {
      try {
        let deit_data = that.editIDList.join(',');
        //编辑批量删除历史记录
        await that.api.user.api_useremptyVideoHistory({
          selected: 1,
          ids: deit_data,
        });
        that.openandclo('clearEdit', false);
        that.getPlaylist();
        that.recEdit(true);
        that.$refs.mtop.messTop = '删除成功';
        that.$refs.mtop.mess_show = true;
        setTimeout(() => {
          that.$refs.mtop.mess_show = false;
        }, 3000);
      } catch (error) {
        console.log(error.message);
      }
    },
    //确认删除选中,收藏夹
    async clearfavEdit() {
      try {
        if (!that.editList.length) {
          that.$refs.mtop.messTop = '请选择删除目标';
          that.$refs.mtop.mess_show = true;
          setTimeout(() => {
            that.$refs.mtop.mess_show = false;
          }, 3000);
          return;
        }
        let deit_data = that.editIDList.join(',');
        //编辑批量删除历史记录
        await that.api.user.api_useremptyVideoFavorite({
          selected: 1,
          ids: deit_data,
        });
        that.openandclo('clearFavEdit', false);
        that.getFavlist();
        that.recEdit(true);
        that.$refs.mtop.messTop = '删除成功';
        that.$refs.mtop.mess_show = true;

        setTimeout(() => {
          that.$refs.mtop.mess_show = false;
        }, 3000);
      } catch (error) {
        console.log(error.message);
      }
    },
    //编辑选中
    mcheckRecEdit(item, index) {
      if (!that.editList.filter((itemindex) => itemindex === index).length) {
        that.editList.push(index);
        that.editIDList.push(item.id);
      } else {
        that.editList.splice(that.editList.indexOf(index), 1);
        that.editIDList.splice(that.editList.indexOf(item.id), 1);
      }
    },
    //点击编辑
    recEdit(type) {
      if (!type) {
        //点击编辑
        that.editShow = true;
      } else {
        //点击取消编辑
        that.editShow = false;
        that.editList = [];
        that.editIDList = [];
      }
    },
    // 跳转指定播放页
    goto(item, index) {
      if (that.editShow) {
        that.mcheckRecEdit(item, index);
      } else {
        that.YS.routetogo({
          path: '/mplay',
          query: { video_id: item.video_id },
        });
      }
    },
    //历史播放记录跳转
    seekgoto(item, index) {
      if (that.editShow) {
        that.mcheckRecEdit(item, index);
      } else {
        that.YS.routetogo({
          path: '/mplay',
          // query: { video_id: item.id, seek: item.elapsed, lid: item.video_fragment_symbol },
          query: { video_id: item.video_id, lid: item.video_fragment_id, seek: item.elapsed },
        });
      }
    },
    cliTag(i) {
      (that.firstLoading = false),
        // that.YS.actgo('m_record_tag-list', 'm_record_tag-list-act', i);
        (that.topact = i);
      that.isrec = i == 0 ? true : false;
      if (i == 0) {
        if (!that.playList) that.getFavlist();
      } else if (i == 1) {
        if (!that.playList2) that.getPlaylist();
      }
      that.YS.toTop2();
      that.recEdit(true);
    },
    // api获取收藏夹
    getFavlist() {
      fetch.api_videoFavorite(that.pages_fav).then((data) => {
        that.firstLoading = true;
        if (data) {
          that.playList = data.video_favorite_list;
          that.pagetotal = data.video_favorite_total;
        }
      });
    },
    // api获取收藏夹
    pushGetFavlist() {
      that.loadApiNum += 1;
      fetch
        .api_videoFavorite(that.pages_fav)
        .then((data) => {
          that.firstLoading = true;
          if (data) {
            if (data.video_favorite_list) {
              data.video_favorite_list.forEach((e) => {
                that.playList.push(e);
              });
            } else {
              that.favMAX = true;
            }
          }
        })
        .finally(() => {
          that.loadApiNum -= 1;
        });
    },
    //api获取播放历史记录列表
    getPlaylist() {
      fetch.api_videoHistory(that.pages_his).then((data) => {
        that.firstLoading = true;
        if (data) {
          that.playList2 = data.video_history_list;
          that.pagetotal2 = data.video_history_total;
        }
        // console.log(data);
      });
    },
    //api获取播放历史记录列表
    pushGetPlaylist() {
      that.loadApiNum += 1;

      fetch
        .api_videoHistory(that.pages_his)
        .then((data) => {
          that.firstLoading = true;
          if (data) {
            if (data.video_history_list) {
              data.video_history_list.forEach((e) => {
                that.playList2.push(e);
              });
            } else {
              that.hisMAX = true;
            }
          }

          // console.log(data);
        })
        .finally(() => {
          that.loadApiNum -= 1;
        });
    },
    openandclo(type, boo) {
      that[type] = boo;
      var coverm = document.querySelector('#coverm');
      if (boo) {
        coverm.style.display = 'block';
      } else {
        coverm.style.display = 'none';
      }
    },
    //打开确认清空弹窗
    clearhis() {
      that.openandclo('clearR', true);
    },
    //清空历史记录
    clearhisYes() {
      localStorage.removeItem('not_logged_history');
      that.api.user.api_useremptyVideoHistory({});
      that.openandclo('clearR', false);
      that.playList2 = null;
    },
    // 滚动条距离页面顶部的距离
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let seeheight = document.documentElement.clientHeight || document.body.clientHeight;
      let mrebox = document.getElementsByClassName('m_total')[0].offsetHeight;
      // let m_home_block = document.getElementsByClassName('top_block')[0].offsetHeight || 0;
      // let filheight = filbox + m_home_block;
      if (that.isrec) {
        //收藏夹
        if (
          scrollTop + seeheight > mrebox - 10 &&
          that.playList &&
          !that.favMAX &&
          (that.pages_fav.page += 1)
        ) {
          that.pushGetFavlist();
        }
      } else {
        // 历史记录
        if (
          scrollTop + seeheight > mrebox - 10 &&
          that.playList2 &&
          !that.hisMAX &&
          (that.pages_his.page += 1)
        ) {
          that.pushGetPlaylist();
        }
      }

      //筛选页滑到下面时
      // console.log(filheight);
      // console.log(scrollTop + seeheight);
      // if (
      //   that.video_list &&
      //   filbox &&
      //   filheight <= scrollTop + seeheight + 200 &&
      //   (that.fy.pages.page += 1)
      // ) {
      //   //加载第N页，并添加到里面
      //   if (that.fy.pages.page < that.fy.pagenum) {
      //     that.isloading = true;
      //     that.pushvideo_list();
      //     that.istobottom = false;
      //   }
      //   if (that.fy.pages.page == that.fy.pagenum) {
      //     that.isloading = true;
      //     that.pushvideo_list();
      //     that.istobottom = true;
      //   }
      // }
    },
  },
};
</script>
<style lang="scss" scope></style>
